import _ from 'lodash'
import currency from 'currency.js'

export const formatIntegral = (val = 0) => {
  const num = parseInt(val).toString().replace(/\B(?=(\d{3})+\b)/g, ',')
  return num
}

export const getPrenct = (val) => {
  // 礼卷折扣卷除以总价，然后四舍五入保留小数后两位
  const percentage = _.round((val.couponamount / val.subtotal) * 100, 2)
  // 算出总优惠力度
  const per = val.discountamount / val.subtotal
  // 算出单价折扣价，四舍五入保留小数后两位
  const price = _.round(val.sprice - val.sprice * per, 2)
  // 判断是否有无门槛优惠券，有则减去固定金额，没有则显示空
  const price3 = val.coupon3 ? '- ￥' + val.couponamount3 : ''
  // 输出格式：- 50% -￥50 = ￥1000
  return `- ${percentage}% ${price3} = ￥${price}`
}

export const currencyFormat = (val) => {
  return currency(val)
}